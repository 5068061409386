.tg-edit {
	background: #eee;
	height: auto !important;
}

.tg-form-edit .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners,
.ck.ck-toolbar {
	border: none;
	border-top: 1px solid #eee;
	border-radius: 0 !important;
}

.tg-form-edit .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners,
ck.ck-toolbar:active {
	border-color: #eee !important;
}

.tg-form-edit .ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
	box-shadow: none !important;
}

.tg-form-edit .ck.ck-toolbar .ck.ck-toolbar__separator {
	background: none !important;
}

.tg-form-edit .imageField .ant-form-item-explain-error {
	padding-left: 1rem;
}

.tg-form-edit .titleEditor .ant-input-textarea-suffix {
	display: none !important;
}

.tg-form-edit .imageField {
	border-bottom: 1px solid #eee;
}

.fill-up::before {
	content: '';
	width: 100%;
	left: 0;
	bottom: 0;
	background: #2780e8;
	opacity: 0.8;
	z-index: 97;
	position: absolute;
	animation: grow 1.7s forwards;
}

@keyframes grow {
	from {
		height: 0%;
	}

	to {
		height: 100%;
	}
}

.progress-fill {
	--borderW: 4px;
	position: relative;
	display: inline-grid;
	place-content: center;
	aspect-ratio: 11 / 5;
	height: 3em;
	border: var(--borderW) solid gainsboro;
	border-radius: 28px;
	user-select: none;
	box-sizing: border-box;
	box-shadow: 0 0 20px 2px #e2635c;
}
.progress-fill::before {
	content: '';
	position: absolute;
	inset: calc(-1 * var(--borderW));
	border: var(--borderW) solid rgb(255, 255, 255);

	border-radius: 28px;
	animation: polygon 1.7s linear;
}

@media (max-width: 600px) {
	.progress-fill {
		border-radius: 24px;
	}
	.progress-fill::before {
		border-radius: 24px;
	}
}

@keyframes polygon {
	0% {
		clip-path: polygon(50% 50%, 50% 0%, 0% 0%, 0% 100%, 100% 100%, 100% 0%, 50% 0%);
	}
	17.1875% {
		clip-path: polygon(50% 50%, 50% 0%, 0% 0%, 0% 100%, 100% 100%, 100% 0%, 100% 0%);
	}
	32.8125% {
		clip-path: polygon(50% 50%, 50% 0%, 0% 0%, 0% 100%, 100% 100%, 100% 100%, 100% 100%);
	}
	67.1875% {
		clip-path: polygon(50% 50%, 50% 0%, 0% 0%, 0% 100%, 0% 100%, 0% 100%, 0% 100%);
	}
	82.8125% {
		clip-path: polygon(50% 50%, 50% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%);
	}
	100% {
		clip-path: polygon(50% 50%, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
	}
}
